import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import StatBoxReduced from "../../components/StatBoxReduced";
import { getNetworkMap, getNetworkStats } from "../../data/queries";
import { useQueries } from "react-query";
import NetworkMap from "../../components/Network";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import HubIcon from '@mui/icons-material/Hub';
import ComputerIcon from '@mui/icons-material/Computer';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const apiData = useQueries([
    { queryKey: ['networkMap', 1], queryFn: getNetworkMap },
    { queryKey: ['networkStats', 2], queryFn: getNetworkStats }
  ]
  );

  if (apiData[0].isLoading) {
    return <div>Loading data...</div>
  }

  if (apiData[1].isLoading) {
    return <div>Loading data...</div>
  }

  const capacity = apiData[1].data.capacity.toLocaleString("en-US") + " sats";

  return <Box m="20px">
    
    {/* GRID & CHARTS */}
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="140px"
      gap="20px"
    >
      {/* ROW 1 */}
      <Box
        gridColumn="span 4"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StatBoxReduced
          title={apiData[1].data.channels}
          subtitle="Channels"
          icon={
            <HubIcon
              sx={{ color: colors.redAccent[600], fontSize: "26px" }}
            />
          }
        />
      </Box>
      <Box
        gridColumn="span 4"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StatBoxReduced
          title={capacity}
          subtitle="Capacity"
          icon={
            <CurrencyBitcoinIcon
              sx={{ color: colors.redAccent[600], fontSize: "26px" }}
            />
          }
        />
      </Box>
      <Box
        gridColumn="span 4"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StatBoxReduced
          title={apiData[1].data.nodes}
          subtitle="Nodes"
          icon={
            <ComputerIcon
              sx={{ color: colors.redAccent[600], fontSize: "26px" }}
            />
          }
        />
      </Box>

      {/* ROW 3 */}
      <Box
        gridColumn="span 12"
        gridRow="span 6"
        backgroundColor={colors.primary[400]}
        padding="30px"
      >
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ marginBottom: "10px" }}
        >
          Network Map
        </Typography>
        <Typography variant="h5" sx={{ color: colors.redAccent[500] }}>
          Only nodes with channels to the network are displayed
        </Typography>
        <Box height="75vh">
          <NetworkMap isDashboard={true} />
        </Box>
      </Box>

    </Box>
  </Box>
};

export default Dashboard;