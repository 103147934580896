import { ResponsiveNetwork } from "@nivo/network";
import { useQuery } from "react-query";
import { getNetworkMap } from "../data/queries";
import { useTheme } from '@nivo/core'
import { Chip } from '@nivo/tooltip'
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function onNodeClick(node) {
  console.log(node.data.amboss)
  window.open(node.data.amboss);
}

function LightningNodeToolTip(data) {
  const theme = useTheme()
  const capacity = data.node.data.capacity.toLocaleString("en-US") + " sats";
  if (data.node === undefined) return null
  let content = (
    <div>
      <div style={theme.tooltip.basic}>
        {<Chip color={data.node.color} style={theme.tooltip.chip} />}
        {data.node.id}
      </div>
      <div>
        <p><Typography sx={{ color: data.node.color }}>Capacity</Typography>{capacity}</p>
      </div>
    </div>
  )

  return <div style={theme.tooltip.container}>{content}</div>
}


const NetworkMap = () => {
  const { data, isLoading } = useQuery(["networkMap"], getNetworkMap, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const chartTheme = {

    "textColor": "#333333",
    "fontSize": 11,
    "tooltip": {
      "container": {
        "background": "#ffffff",
        "color": "#333333",
        "fontSize": 12
      },
      "basic": {},
      "chip": {},
      "table": {},
      "tableCell": {},
      "tableCellValue": {}
    }
  }
  if (isLoading) {
    return <div>Loading data...</div>
  }

  return (
    <ResponsiveNetwork
      data={data}
      margin={{ top: 0, right: 0, bottom: 80, left: 0 }}
      padding={0.3}
      linkDistance={function (e) { return 2 * e.distance }}
      centeringStrength={0.1}
      repulsivity={30}
      theme={chartTheme}
      nodeSize={function (n) { return n.size }}
      activeNodeSize={function (n) { return 1.2 * n.size }}
      nodeColor={function (e) { return e.color }}
      nodeTooltip={LightningNodeToolTip}
      onClick={function(node) {onNodeClick(node)}}
      nodeBorderWidth={3}
      nodeBorderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.8
          ]
        ]
      }}
      linkThickness={function (n) { return 2 * n.target.data.height }}
      linkBlendMode="multiply"
      motionConfig="wobbly"
    />
  );
};

export default NetworkMap;
