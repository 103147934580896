import axios from "axios";

export const axiosClient = axios.create({
    baseURL: `https://api.nordlysln.net`
});

const getPlmnStats = async () => {
    const { data } = await axiosClient.get(
        "rejects/stats/plmn"
    );

    return data;
};

const getBandStats = async () => {
    const { data } = await axiosClient.get(
        "rejects/stats/band"
    );

    return data;
};

const getNetworkStats = async () => {
    const { data } = await axiosClient.get(
        "api/stats"
    );

    return data;
};


const getNetworkMap = async () => {
    const { data } = await axiosClient.get(
        "api/map"
    );

    return data;
};

const getChannelStats = async () => {
    const { data } = await axiosClient.get(
        "rejects/stats/channel"
    );

    return data;
};

const getRangeStats = async () => {
    const { data } = await axiosClient.get(
        "rejects/stats/range"
    );

    return data;
};

const getRejects = async () => {
    const { data } = await axiosClient.get(
        "rejects"
    );

    return data;
};

const getRecentRejects = async () => {
    const { data } = await axiosClient.get(
        "rejects/recent"
    );

    return data;
};

const getRfLog = async () => {
    const { data } = await axiosClient.get(
        "rflogs"
    );

    return data;
};

const getRecurringRejects = async () => {
    const { data } = await axiosClient.get(
        "rejects/recurring"
    );

    return data;
};

const getRejectCount = async () => {
    const { data } = await axiosClient.get(
        "rejects/stats/count"
    );

    return data;
};

const deleteReject = async (id) => {
    const { data } = await axiosClient.delete(
        `rejects/reject/by-id/${id}`
    );

    return data;
}

const deleteRejectsByImsi = async (imsi) => {
    const { data } = await axiosClient.delete(
        `rejects/reject/by-imsi/${imsi}`
    );

    return data;
}

export { getPlmnStats, getRejects, getRecentRejects, getRfLog, getRecurringRejects, getRejectCount, deleteReject, deleteRejectsByImsi, getBandStats, getChannelStats, getRangeStats, getNetworkMap, getNetworkStats }
